import React from 'react'
import topFoldSVGReversed from './illustrations/topFoldSVGReversed.svg'

const About = () => {
  return (
    <section id="about">
      <div className="pb-10 md:flex md:flex-row-reverse md:pb-16 lg:pb-20 about-layout">
        <div className="px-4 md:w-1/2 md:justify-center md:flex md:flex-col">
          <div className="mb-6 md:mb-0 md:max-w-xl lg:pl-20">
            <h2 className="mb-4 heading">
              Voz em texto com alta precisão a partir de apenas R$0.35/min
            </h2>
            <p className="sub-heading">
              Com que frequência você se esquece de detalhes importantes ou de uma grande ideia? Quer se trate de uma reunião acelerada, consulta médica ou ideias criativas aleatórias - grave-as como ditado de voz, revise-as como texto. Economize tempo. Economize dinheiro.
            </p>
          </div>
        </div>
        <div className="hidden md:w-1/2 md:block md:pr-10 img-container">
          <img
            width="663"
            height="404"
            src={topFoldSVGReversed}
            alt="About"
            className="about-img"
            decoding="async"
          />
        </div>
      </div>
    </section>
  )
}

export default About
