import React from 'react'
import AppStoreDarkLargeSVG from '../icons/appStoreDarkLargeSVG'

const Pricing = ({ appStoreLink }) => {
  return (
    <section id="pricing">
      <div className="pt-4 md:pt-12 layout-space">
        <div className="max-w-6xl pb-12 mx-auto lg:px-10 md:pb-20 xl:pb-24">
          <div className="mb-12 md:text-center md:mb-16">
            <h2 className="mb-4 heading">Preços</h2>
            <p className="md:max-w-xl sub-heading md:mx-auto">
              O app VoxRec é GRATUITO para download. Nossos recursos de gravação são totalmente GRATUITOS. Oferecemos assinaturas mensais ou opções de pagamento conforme o uso para o serviço de voz para texto.
            </p>
          </div>

          <div className="md:flex md:flex-row-reverse md:items-center">
            <div className="text-center md:flex md:flex-wrap md:text-left xl:w-3/5 md:w-1/2 md:items-start">
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-8">
                <h5 className="point-title">Destacar na Reprodução</h5>
                <p className="small">
                  Acompanhe o áudio e a discussão com destaque visual de palavras durante a reprodução. Toque em qualquer palavra para iniciar a reprodução.
                </p>
              </div>
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-8">
                <h5 className="point-title">Formatar &amp; Editar</h5>
                <p className="small">
                  Adicione notas às gravações, edite transcrições, formate ou destaque texto diretamente no seu iPhone ou iPad.
                </p>
              </div>
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-0">
                <h5 className="point-title">Marque momentos importantes</h5>
                <p className="small">
                  Quando a discussão ficar séria, marque os momentos importantes da gravação. Volte a eles com um toque.
                </p>
              </div>
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-0">
                <h5 className="point-title">Backup &amp; Sincronização</h5>
                <p className="small">
                  Dropbox, Google Drive ou iCloud. Suas gravações e transcrições serão arquivadas com segurança.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 xl:w-2/5 card-background-image">
              <div className="z-10 max-w-sm px-6 py-8 mx-auto bg-white shadow-lg md:mx-0 md:px-10 md:py-12 rounded-xl bg-10">
                <div className="mb-4">
                  <p className="from">a partir de</p>
                </div>
                <div className="flex items-end mb-4">
                  <h3 className="big-money">R$0.35</h3>
                  <p className="mb-1 text-black md:text-3xl">
                    &nbsp;/&nbsp;minuto
                  </p>
                </div>
                <hr className="mb-2" />
                <h4 className="mb-6 text-accent">
                  30 minutos GRÁTIS{' '}
                  <span className="lg:hidden md:hidden text-accent">
                    de voz em texto
                  </span>
                </h4>
                <div className="md:justify-between md:flex md:items-center">
                  <a href={appStoreLink} target="_blank" rel="noreferrer">
                    <AppStoreDarkLargeSVG className="mb-4 app-store-link md:mb-0 md:mr-8" />
                  </a>
                  <p className="small">
                    Disponível para iPhone, <br />
                    iPad &amp; Apple Watch
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Pricing
