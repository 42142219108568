import React from 'react'

// import StarHalfSVG from '../images/half-star.svg'
import StarSVG from '../images/star.svg'
import StarEmptySVG from '../images/empty-star.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core'

import BrazilFlag from '../images/flags/brazil-flag.svg'
import USAFlag from '../images/flags/usa-flag.svg'
import RussiaFlag from '../images/flags/russia-flag.svg'
import NetherlandsFlag from '../images/flags/netherlands-flag.svg'
import GermanyFlag from '../images/flags/germany-flag.svg'
import CzechFlag from '../images/flags/czech-flag.svg'
import AustraliaFlag from '../images/flags/australia-flag.svg'
import UKFlag from '../images/flags/uk-flag.svg'
import ItalyFlag from '../images/flags/italy-flag.svg'

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay])

const Reviews = () => {
  return (
    <section id="reviews" className="pt-6 pb-12 md:pb-24 lg:pb-32 md:pt-20">
      <div className="layout-space lg:pb-8">
        <h2 className="pb-4 heading md:text-center">Avaliações da AppStore</h2>
        <p className="pb-8 sub-heading md:text-center md:max-w-xl md:mx-auto">
          Com milhares de avaliações e centenas de análises em todo o mundo, selecionamos algumas que melhor representam a experiência de nosso app e serviço de transcrição de texto.
        </p>
      </div>
      <Swiper
        spaceBetween={15}
        margin={50}
        slidesPerView={'auto'}
        // centeredSlides
        // loop
        navigation
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
        }}
        speed={500}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={swiper => console.log(swiper)}
      >
        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={USAFlag} alt="U.S.A Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">Huge timesaver</h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                I love this app. It’s great for on-the-go thoughts. You can
                record a to-do list, compose an email, write a paper, and so
                much more! You just record your voice and it transcribes it for
                you!
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={USAFlag} alt="U.S.A Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">Love this app!</h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                This works great for drafting a novel. I can record, the app
                transcribes and then emails it to me! If the app gets it wrong
                and I can’t remember what I said it’s super easy to go back in
                the transcription and listen to the right spot.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={USAFlag} alt="U.S.A Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">Very powerful</h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                I’ve been looking for an app like this. I often get frustrated
                with listening to voice recordings because a lot of recordings
                are filled with parts that are not relevant to me. The
                transcription feature helps me to skip back and forth. This
                helps me focus on important parts and save time. Lots of other
                useful features too.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={USAFlag} alt="U.S.A Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">
                  This app actually works!!!
                </h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img
                    src={StarEmptySVG}
                    alt="star"
                    className="w-4 h-4 mr-2px"
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                I have tried, I’m guessing nearly every single transcription
                app. They all proclaim they can record live audio, then
                transcribe. Bull hockey! This is the only one that actually
                transcribed my audio quickly into a readable text. The
                directions are super simple. I am very impressed the
                transcription is as accurate as it is. Maybe 3-5% of the words
                are not what was actually said. When I get a raise, I am
                subscribing ASAP! :)
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={NetherlandsFlag} alt="Netherlands Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">Great!</h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img
                    src={StarEmptySVG}
                    alt="star"
                    className="w-4 h-4 mr-2px"
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                The transcription is much better than all other apps I’ve used.
                I get some odd words and sentences every now and then, but it
                also depends on how I pronounce my text. Some mistakes I can
                figure out because it’s just 1 or 2 words in a row that are
                transcripted wrong, but most of the time very consequently.
                Which, by the way, can be a big laugh.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={NetherlandsFlag} alt="Netherlands Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">Interviews</h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                A few weeks ago I purchased Voxrec to help me to transcribe
                interviews. A cool feature is that you can directly share the
                recording and transcription with other devices like your laptop,
                which is really convenient.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={RussiaFlag} alt="Russia Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">Качество</h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                Перешёл от конкурентов. Но, это и высокая оценка - аванс
                разработчикам. Нужна гибкая и разумная политика ценообразования.
                Нужна версия для iPad. И подумайте по поводу интеграции,
                транспорта. Например, Notability, Marginnote. Спасибо и успехов.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={RussiaFlag} alt="Russia Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">Хороший функционал</h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img
                    src={StarEmptySVG}
                    alt="star"
                    className="w-4 h-4 mr-2px"
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                Это приложение, помимо расширенных функций диктофона, очень
                выручает на долгих митингах и освобождает от необходимости
                делать пометки вручную или прослушивать всю запись заново.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={CzechFlag} alt="Czech Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">Výborný!</h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                Moc doporučuji! Nemám v přepisu překlepy, a líbí se mi, že text
                mi přijde na email. Paráda!
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={GermanyFlag} alt="Germany Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">
                  Einfache UI zum Audio aufnehmen
                </h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                Mit der App kann man einfach etwas aufnehmen und automatisch
                transkribieren lassen. Das spart Zeit. Man kann den Text
                selektieren und dann genau zu der Stelle in der Aufnahme
                springen. Praktisch!
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={GermanyFlag} alt="Germany Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">
                  Funktioniert auch in Deutsch
                </h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                Es ist das erste mal das ich solch eine App verwende und ich
                finde sie sehr hilfreich. Ich denke ich werde sie in Zukunft in
                Meetings einsetzen um ein Protokoll anzufertigen und meine
                spontanen Gedanken festzuhalten. Die App funktioniert auch in
                Deutsch hervorragend. Das finde ich klasse weil viele Apps auf
                Englisch optimiert sind.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={AustraliaFlag} alt="Australia Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">Lifesaver</h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                Saves so much time! Allows me to achieve what I otherwise
                wouldn’t due to ptsd and not being able to relive and express
                experiences slowly which happens if typing out. This way I can
                just speak it and it’s less difficult and less anxiety
                promoting. Great app, need to speak clearly of course and a
                little editing here and there is likely but it’s worth having
                definitely.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={BrazilFlag} alt="Brazil flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">Transformational</h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                Really getting a lot out of this app. Using it mainly to record
                and transcribe meetings. The accuracy is actually quite scary,
                and I find it allows me to focus more on the meeting knowing I
                will have a full transcript to refer back to. Really recommend
                it.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={UKFlag} alt="United Kingdom Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">My go to Voice App</h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                I try various Apps and then pick the best of the bunch. This is
                it. I’m even adding Podcasts and Screencastify audio here to
                listen back. Excellent. Recommended!
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={ItalyFlag} alt="Italy Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">Straordinaria!</h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                Quest’app mi ha facilitato con lo studio universitario, è molto
                facile da usare, la trascrizione è ottima e ha addirittura la
                funzione di segnare in tempo reale i momenti più importante
                della registrazione. Buonissima!
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="px-4 py-6 bg-white shadow-lg rounded-xl">
            <div className="flex items-center pb-4 mb-4 border-bottom-grey">
              <div className="mr-6">
                <img src={ItalyFlag} alt="Italy Flag" />
              </div>
              <div>
                <h5 className="text-base mb-2px bold">
                  Registratore vocale e trascrizione vocale
                </h5>
                <div className="flex">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                </div>
              </div>
            </div>
            <div>
              <p className="small">
                Facile da usare, possibilità di salvare le registrazioni sui
                cloud e precisa trascrizione. Davvero un’ottima app! Dopo
                l’aggiornamento è ancora più precisa e veloce!
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}
export default Reviews
